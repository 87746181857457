import React, {useState} from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import Divider from '@mui/material/Divider';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';
import Collapse from '@mui/material/Collapse';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';

import GoogleMapsIcon from './GoogleMapsIcon'; // Make sure the path is correct

function ControlPanel({ open, onGoogleMapsClick, onCenterClick, toggleTileGrid }) {
  const [isExpanded, setIsExpanded] = useState(true);
  const handleToggle = () => setIsExpanded((prev) => !prev); // Add this line
  const [isVisibleIcon, setIsVisibleIcon] = useState(false);
  return (
    (open && (
      <>
        {!isExpanded && (
          <Tooltip title="Open Task Bar" placement='left'>
          <IconButton
            aria-label='Open Task Bar'
            onClick={handleToggle}
            sx={{
              position: 'absolute',
              top: 5,
              right: 5,
              zIndex: 1000,
              backgroundColor: 'white',
              borderRadius: "5px",
              boxShadow: 3,
              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>
          )}
        <Collapse in={isExpanded} timeout="auto" >
        <ButtonGroup
          aria-label="control button group"
          orientation="vertical"
          variant="contained"
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
            zIndex: 1000,
            backgroundColor: 'white',
            '& .MuiIconButton-root': {
              width: '100%',
              borderRadius: 0,
            },
          }}
        >
          <Tooltip title='Open in Google Maps' placement='left'>
            <IconButton
              aria-label='Google Maps'
              onClick={onGoogleMapsClick}
            >
              <SvgIcon component={GoogleMapsIcon} viewBox="0 0 512 512" sx={{ fontSize: 25 }} />
            </IconButton>
          </Tooltip>
          <Divider />
          <Tooltip title='Center Map' placement='left'>
            <IconButton
              aria-label='Center Map'
              onClick={onCenterClick}
              >
              <CenterFocusStrongIcon />
            </IconButton>
          </Tooltip>
          <Divider />
          <Tooltip title='Copy URL to Clipboard' placement='left'>
            <IconButton
              aria-label='Copy URL to Clipboard'
              onClick={() => {
                if (window.gtag) {
                  window.gtag('event', 'CopyURL', {
                    'event_category': 'key_event',
                    'event_label': 'Copy URL to Clipboard',
                  });
                }
                navigator.clipboard.writeText(window.location.href)}
              } // Replace with your copy function
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <Divider />
          <Tooltip title='Share via Email' placement='left'>
            <IconButton
              aria-label='Share via Email'
              onClick={() => {
                if (window.gtag) {
                  window.gtag('event', 'SendViaEmail', {
                    'event_category': 'key_event',
                    'event_label': 'Send via Email',
                  });
                }
                window.open(`mailto:?subject=Check out this map!&body=${encodeURIComponent(window.location.href)}`)}
              }
            >
              <EmailIcon />
            </IconButton>
          </Tooltip>
          <Divider />
          <Tooltip title={isVisibleIcon ? "Add Tile Grid" : "Remove Tile Grid"} placement='left'>
            <IconButton
              aria-label='Toggle Tile Grid'
              onClick={() => {
                toggleTileGrid();
                setIsVisibleIcon(!isVisibleIcon)
              }}>
                {isVisibleIcon ? <VisibilityIcon/> : <VisibilityOffIcon/>}
            </IconButton>
          </Tooltip>
          <Divider />
          <Tooltip title="Close Task Bar" placement='left'>
            <IconButton
              aria-label='Close Task Bar'
              onClick={handleToggle}>
                {<ChevronRightIcon/>}
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </Collapse>
    </>))
  );
}

export default ControlPanel;
