import React, { useEffect, useState, useRef } from 'react';
import { Grid, CircularProgress, Paper } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import ControlPanel from './ControlPanel';

mapboxgl.accessToken = "pk.eyJ1IjoiY2FtZGVuZnJpdHoiLCJhIjoiY2x0ZGF1YTNkMDFrYzJrcndlbTBxd3ByeSJ9.5hQL7WsnKajEWNuO3_2cRg"; // Replace with your Mapbox access token

function TractMap({geoJSON, isLoading}) {

    const mapContainerRef = useRef(null);
    const mapRef = useRef(null); // Create a ref to hold the map instance

  const [showPanel, setShowPanel] = useState(false);

  const openInGoogleMaps = (coords) => {
    // Extract the first coordinates pair in the GeoJSON
    let lng = coords[0];
    let lat = coords[1];
    // If the coordinates are nested, extract the first pair
    if (lng.length > 1) {
      lat = lng[1];
      lng = lng[0];
    }
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(googleMapsUrl, '_blank');
  };


  function centerMapOnGeoJSON(geoJSON) {
    const bounds = new mapboxgl.LngLatBounds();
  
    const extendBounds = (coordinates) => {
      coordinates.forEach((coord) => {
        if (Array.isArray(coord[0])) {
          extendBounds(coord); // Recursively extend bounds for nested coordinates in Polygons or MultiPolygons
        } else {
          bounds.extend(coord);
        }
      });
    };
  
    const processFeature = (feature) => {
      if (feature.geometry.type === 'Point') {
        bounds.extend(feature.geometry.coordinates);
      } else if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon') {
        extendBounds(feature.geometry.coordinates);
      }
    };
  
    if (geoJSON) {
      if (geoJSON.type === 'FeatureCollection') {
        // If geoJSON is a FeatureCollection, process each feature
        geoJSON.features.forEach(feature => processFeature(feature));
      } else if (geoJSON.type === 'Polygon' || geoJSON.type === 'MultiPolygon') {
        // Directly process a single Polygon or MultiPolygon GeoJSON
        extendBounds(geoJSON.coordinates);
      } else if (geoJSON.type === 'Feature' && (geoJSON.geometry.type === 'Polygon' || geoJSON.geometry.type === 'MultiPolygon')) {
        // Process a single Feature that is a Polygon or MultiPolygon
        processFeature(geoJSON);
      }

      if (!bounds.isEmpty()) {
        mapRef.current.fitBounds(bounds, {
          padding: 150 // Adjust padding as needed
        });
      }
    }
  }

    useEffect(() => {
      setShowPanel(!!geoJSON);
    }, [geoJSON]);


    useEffect(() => {
      if (mapRef.current) return; // Initialize the map only once

      // Initialize the map
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        zoom: 4,
        center: [-95, 38],
      });

      mapRef.current.on('load', function () {
        // Add the source and layer for the 'wms-test-layer'
        mapRef.current.addSource('wms-test-source', {
          type: 'raster',
          tiles: [
            'https://gis.blm.gov/arcgis/rest/services/Cadastral/BLM_Natl_PLSS_CadNSDI/MapServer/export?bbox={bbox-epsg-3857}&bboxSR=3857&imageSR=3857&size=256,256&dpi=96&format=png32&transparent=true&f=image'
          ],
          tileSize: 256,
        });
        mapRef.current.addLayer({
          id: 'wms-test-layer',
          type: 'raster',
          source: 'wms-test-source',
          paint: {},
        });

        // Initialize the layer visibility state to match the icon's visibility state
        mapRef.current.setLayoutProperty('wms-test-layer', 'visibility', 'visible'); // Set the initial visibility
      });

      return () => {
        // Clean up
      };
    }, []);


    useEffect(() => {
      if (!geoJSON || !mapRef.current) return;

      const onLoad = () => {
        // Determine if geoJSON is an array and merge if necessary
        const effectiveGeoJSON = geoJSON

        const source = mapRef.current.getSource('geojson-layer');
        // If the source exists, update its data
        if (source) {
          source.setData(effectiveGeoJSON);
        } else {
          // If the source doesn't exist, add it along with associated layers
          mapRef.current.addSource('geojson-layer', {
            type: 'geojson',
            data: effectiveGeoJSON
          });

          mapRef.current.addLayer({
            id: 'geojson-layer-level',
            type: 'fill',
            source: 'geojson-layer',
            paint: {
              'fill-color': '#0080ff',
              'fill-opacity': 0.5,
            },
          });

          mapRef.current.addLayer({
            id: 'outline',
            type: 'line',
            source: 'geojson-layer',
            paint: {
              'line-color': '#000',
              'line-width': 3,
            },
          });
        }
        // Recenter map on the GeoJSON
        centerMapOnGeoJSON(effectiveGeoJSON);
      };

      if (mapRef.current.isStyleLoaded()) {
        // If the style is already loaded, proceed to add sources and layers
        onLoad();
      } else {
        // Wait for the style to load before adding sources and layers
        mapRef.current.once('load', onLoad);
      }

      return () => {
        mapRef.current.off('load', onLoad);
      };
    }, [geoJSON]);


    const toggleLayerVisibility = () => {
      if (!mapRef.current) return;
      const visibility = mapRef.current.getLayoutProperty('wms-test-layer', 'visibility');
      const newVisibility = visibility === 'visible' ? 'none' : 'visible';
      mapRef.current.setLayoutProperty('wms-test-layer', 'visibility', newVisibility);
      // Toggle the icon state to reflect the new visibility state of the layer
    };

  return (
    <Grid item xs={12} md={8} style={{ display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: "2.5%",
          position: "relative",
          minHeight: '400px'}}>
      <div ref={mapContainerRef} style={{ minHeight: '300px', height: '100%', width: '100%', borderRadius: '5px' }}>
      <ControlPanel open={showPanel} onGoogleMapsClick={() => openInGoogleMaps(geoJSON.coordinates[0][0])} onCenterClick={() => centerMapOnGeoJSON(geoJSON)} toggleTileGrid={toggleLayerVisibility}/>
      {isLoading && (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // semi-transparent white background
          }}>
            <CircularProgress />
          </div>
        )}
      </div>
      </Grid>
  )
}

export default TractMap;