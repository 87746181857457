import React, { useState } from 'react';
import { Grid, Paper } from '@mui/material';
import TractMap from './TractMap';
import 'mapbox-gl/dist/mapbox-gl.css';

import PlssForm from './PlssForm';


function SingleShape() {
  const [geoJSON, setGeoJSON] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Paper sx={{
      margin: "auto",
      width: "100%",
      display: "flex",
    }}>
      <Grid container spacing={0} style={{
        margin: 0,
        width: "100%",
        height: "100%",
        minHeight: "80vh"
        }}>
      <Grid item xs={12} md={4} sx={{
        display: 'flex',
        justifyContent: 'center', // Center children horizontally
        alignItems: 'center', // Center children horizontally in the flex container
        padding: "2.5%",
        position: "relative",
        minHeight: '400px',
      }}>
        < PlssForm setGeoJSON={setGeoJSON} setIsLoading={setIsLoading}/>
        </Grid>
        <TractMap geoJSON={geoJSON} isLoading={isLoading} />
      </Grid>
    </Paper>
  );
}

export default SingleShape;
