import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Typography,
  IconButton
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'; // Import the download icon

function DownloadParcelDialog({ isDownloadDialogOpen, closeDownloadDialog, downloadGeoJSON, downloadShapefile }) {
  const [fileType, setFileType] = useState('shp');
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState('');

  const handleDownload = async () => {
    setIsDownloading(true);
    setDownloadError(''); // Clear any previous errors
    try {
      if (fileType === 'shp') {
        await downloadShapefile();
      } else {
        await downloadGeoJSON();
      }
      closeDownloadDialog(); // Only close dialog on success
    } catch (error) {
      console.error('Download failed:', error);
      setDownloadError('Failed to download the file. Please try again. Contact plssgeoconvert@gmail.com if problem persists.'); // Display an error message
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Dialog open={isDownloadDialogOpen} onClose={() => { if (!isDownloading) closeDownloadDialog(); }} PaperProps={{
        style: {
          minWidth: '300px', // Apply a minimum width unless on small devices
          minHeight: '200px', // Apply a minimum height unless on small devices
        },
      }}>
      <DialogTitle style={{ display: 'flex', alignItems: 'center'}}>
      <IconButton edge="start" color="inherit" disabled={isDownloading} style={{ pointerEvents: 'none'}}>
          <CloudDownloadIcon /> {/* This is the download icon */}
        </IconButton>
        Download Parcel
      </DialogTitle>
      <DialogContent  style={{ padding: '2vh' }}>
        <FormControl fullWidth>
          <InputLabel id="file-type-select-label">File Type</InputLabel>
          <Select
            labelId="file-type-select-label"
            id="file-type-select"
            value={fileType}
            label="File Type"
            onChange={(event) => setFileType(event.target.value)}
          >
            <MenuItem value="shp">Shapefile</MenuItem>
            <MenuItem value="geojson">GeoJSON</MenuItem>
          </Select>
        </FormControl>
        {/* Conditionally render the error message */}
        {downloadError && (
          <Typography color="error" align="center">
            {downloadError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { if (!isDownloading) closeDownloadDialog(); }} disabled={isDownloading}>
          Cancel
        </Button>
        <Button
          onClick={handleDownload}
          startIcon={isDownloading ? <CircularProgress size={14} /> : null}
          disabled={isDownloading}
        >
          {isDownloading ? '' : 'Download'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DownloadParcelDialog;
