import React, { useEffect, useState } from 'react';
import {useSearchParamsState} from "./hooks/useSearchParamsState";
import { TextField, MenuItem, Select, FormControl, InputLabel, Box, FormHelperText, InputAdornment, Button} from '@mui/material';
import statesWithCounties from './StateCounties';
import DownloadParcelDialog from './DownloadDialog';


function PlssForm(props) {
  const [selectedState, setSelectedState] = useSearchParamsState('state', '');
  const [selectedCounty, setSelectedCounty] = useState('');
  const [townshipRangeSection, setTownshipRangeSection] = useSearchParamsState('trs', '');
  const [townshipError, setTownshipError] = useState('');
  const [validTRS, setValidTRS] = useState(false);
  const [fetchError, setFetchError] = useState('');
  const [validParcel, setValidParcel] = useState(false);
  const [qqCalls, setQQCalls] = useSearchParamsState('qq', '');
  const [qqCallsParsed, setQQCallsParsed] = useState('');
  const [isDownloadDialogOpen, setDownloadDialogOpen] = useState(false)
  const openDownloadDialog = () => {
    setDownloadDialogOpen(true);
    if (window.gtag) {
      window.gtag('event', 'DownloadParcel', {
        'event_category': 'key_event',
        'event_label': 'Download Parcel',
      });
    }
  }
  const closeDownloadDialog = () => setDownloadDialogOpen(false);
  const setGeoJSON = props.setGeoJSON;
  const setIsLoading = props.setIsLoading;
  const sortedStatesWithCounties = Object.fromEntries(
    Object.entries(statesWithCounties).sort()
  );

  // When a state is selected, reset the county selection and error messages
  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedCounty('');
    setTownshipError('');
    setGeoJSON(null);
    if (validTRS) {
      findParcel(event.target.value, selectedCounty, townshipRangeSection);
    }
  };

  const handleCountyChange = (event) => {
    setSelectedCounty(event.target.value);
    if (validTRS) {
      findParcel(selectedState, event.target.value, townshipRangeSection);
    }
  };

  const fetchGeoJSON = async (formData) => {
    setIsLoading(true);
    const response = await fetch(`/plss/create_geojson`, {
      method: 'POST',
      body: formData,
    })
    setValidParcel(response.status === 200);
    if (response.status !== 200) {
      response.json().then((data) => {
        setFetchError(data.error);
        setGeoJSON(null)
      })
    } else {
      response.json().then((data) => {
        setGeoJSON(data.geojson);
      })
      setFetchError('');
    }
    setIsLoading(false)

  }

  const findParcel = async (state, county, trs) => {
    const formData = new FormData();
    formData.append('state', state);
    formData.append('county', county);
    formData.append('trs', trs);
    formData.append('qq', qqCalls);
    fetchGeoJSON(formData);
}

  const qqSubmit = async () => {
    findParcel(selectedState, selectedCounty, townshipRangeSection);
  }


  // Handle Township Range and Section validation
  const handleTownshipChange = async (trs) => {
    const value = trs;
    setTownshipRangeSection(value);
    const formData = new FormData();
    formData.append('trs', value);
    const response = await fetch('/plss/verify_trs', {
        method: 'POST',
        body: formData,
    })
    setValidTRS(response.status === 200);

    if (response.status !== 200) {
      response.json().then((data) => {
        setTownshipError(data.error);
    })} else {
      setTownshipError('');
      findParcel(selectedState, selectedCounty, value);
    }
  };

  // Handle QQ Calls validation
  const handleQQCallsChange = async (qq) => {
    const value = qq
    setQQCalls(value);
    const formData = new FormData();
    formData.append('qq', value);
    const response = await fetch('/plss/verify_qq', {
      method: 'POST',
      body: formData,
    })
    if (response.status !== 200) {
      response.json().then((data) => {
        setFetchError(data.error);
      })
    } else {
      response.json().then((data) => {
        const flattened = data.qq.map(innerList => innerList.join('/')).join(', ');
        setQQCallsParsed(flattened);
      })
    }
  };

  // If there is a TRS in the URL, add it
  useEffect(() => {
    if (townshipRangeSection) {
      handleTownshipChange(townshipRangeSection);
    }
  }, []);

  const downloadGeoJSON = async () => {
    const formData = new FormData();
    formData.append('state', selectedState);
    formData.append('county', selectedCounty);
    formData.append('trs', townshipRangeSection);
    formData.append('qq', qqCalls);
    if (window.gtag) {
      window.gtag('event', 'DownloadGeoJSON', {
        'event_category': 'key_event',
        'event_label': 'Download GeoJSON',
      });
    }

    const response = await fetch(`/plss/download_geojson`, {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${townshipRangeSection}.geojson`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      // Throw an error if the response is not ok
      throw new Error('Failed to download GeoJSON.');
    }
  };

  const downloadShapefile = async () => {
    const formData = new FormData();
    formData.append('state', selectedState);
    formData.append('county', selectedCounty);
    formData.append('trs', townshipRangeSection);
    formData.append('qq', qqCalls);
    if (window.gtag) {
      window.gtag('event', 'DownloadShapefile', {
        'event_category': 'key_event',
        'event_label': 'Download Shapefile',
      });
    }

    const response = await fetch(`/plss/download_shapefile`, {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${townshipRangeSection}.zip`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      // Throw an error if the response is not ok
      throw new Error('Failed to download Shapefile.');
    }
  };
  return (
    <>
    <Box component="form" noValidate autoComplete="off">
      <FormControl fullWidth margin="normal">
        <InputLabel id="state-select-label">State</InputLabel>
        <Select
          labelId="state-select-label"
          id="state-select"
          value={selectedState}
          label="State"
          onChange={handleStateChange}
        >
          {Object.keys(sortedStatesWithCounties).map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal" disabled={!selectedState}>
        <InputLabel id="county-select-label">County (Optional)</InputLabel>
        <Select
          labelId="county-select-label"
          id="county-select"
          value={selectedCounty}
          label="County"
          onChange={handleCountyChange}
        >
          {selectedState && sortedStatesWithCounties[selectedState].map((county) => (
            <MenuItem key={county} value={county}>
              {county}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="Township Range Section"
        placeholder="Format: 1N-1W-10 or 1N1W10"
        fullWidth
        margin="normal"
        value={townshipRangeSection}
        onChange={(e) => handleTownshipChange(e.target.value)}
        error={!validTRS && townshipRangeSection !== ''}
        helperText={townshipError}
      />

      <TextField
        fullWidth
        placeholder="Format: NE/NE, S2NE4, etc."
        label="Quarter-Quarter Descriptions"
        margin="normal"
        value={qqCalls}
        onChange={(e) => handleQQCallsChange(e.target.value)}
        helperText={qqCallsParsed}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="contained"
                onClick={() => {
                  qqSubmit();
                }}
                disabled={!validTRS}
              >
                Submit
              </Button>
            </InputAdornment>
          ),
        }}
      />
      {fetchError && !townshipError && townshipRangeSection !== "" && (
      <FormHelperText error={!validParcel}>
        {fetchError}
      </FormHelperText>
      )}
      <Box sx={{marginTop: "1vh"}}>
        <Button variant="contained" onClick={openDownloadDialog} disabled={!validTRS}>
            Download Parcel
          </Button>
      </Box>
    </Box>
    <DownloadParcelDialog
      isDownloadDialogOpen={isDownloadDialogOpen}
      closeDownloadDialog={closeDownloadDialog}
      downloadGeoJSON={downloadGeoJSON}
      downloadShapefile={downloadShapefile}
    />

  </>
  );
}

export default PlssForm;
