import React, { useEffect, useState, useRef } from 'react';
import AddTractDialog from './AddTractDialog';
import Box from '@mui/material/Box';
import { Grid, Paper, Button } from '@mui/material';

import { DataGrid, } from '@mui/x-data-grid';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'state',
    headerName: 'State',
    width: 150,
    editable: true,
  },
  {
    field: 'TRS',
    headerName: 'TRS',
    width: 150,
    editable: true,
  },
  // {
  //   field: 'Metadata',
  //   headerName: 'metadata',
  //   type: 'json',
  //   width: 110,
  //   editable: true,
  // },
];

export default function TractDataGrid({rows, setRows, downloadShapefile}) {

  const [addTractDialogOpen, setAddTractDialogOpen] = useState(false);


  const addNewTract = (newTractData) => {
    const newRow = {
      id: rows.length + 1, // Or any other logic to generate a unique ID
      ...newTractData,
    };
    setRows([...rows, newRow]);
  };

  return (
    <>
    <Box sx={{ width: '100%' }}>
      <DataGrid
      sx={{maxHeight: "60vh", minHeight: "40vh"}}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        checkboxSelection
        disableRowSelectionOnClick
        />
        <Box sx={{ margin: "1vh", textAlign: "center" }}>
          <Button sx={{margin: "1vh"}} variant="contained" onClick={() => setAddTractDialogOpen(true)}>Add Tract</Button>
          <Button sx={{margin: "1vh"}} variant="contained" onClick={() => downloadShapefile()}>Download Tracts</Button>
        </Box>

      </Box>
        <AddTractDialog isOpen={addTractDialogOpen} setIsLoading={() => {}} onClose={() => setAddTractDialogOpen(false)} addNewTract={addNewTract}/>
      </>

  );
}
