import React, { useState } from 'react';
import { Container, Grid, TextField, Button, Link, Typography, Box } from '@mui/material';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [emailResponse, setEmailResponse] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('email', email);
    const response = await fetch(`/email/add`, {
        method: 'POST',
        body: formData,
    });
    if (response.ok) {
        setEmailResponse('Email added successfully!');
        setEmail('');
    } else {
        setEmailResponse('Error adding email.');
    }
  };

  return (
    <Box component="footer" sx={{ backgroundColor: 'primary.dark', color: 'white', py: 3 }}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" color="inherit">
              Contact Us
            </Typography>
            <Typography variant="subtitle1" color="inherit">
              <Link style={{ color: 'white' }} href="mailto:plssgeoconvert@gmail.com">plssgeoconvert@gmail.com</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography variant="h6" sx={{ paddingBottom: "2vh" }} color="inherit">
            Interested in Batch Conversion?
            </Typography>
            <form onSubmit={handleSubmit} style={{
                display: 'flex',
                flexDirection: 'row', // Align form items in a row
                justifyContent: 'center', // Center form items horizontally
                alignItems: 'center' // Align form items vertically
            }}>
            <TextField
                size="small"
                label="Enter your email"
                variant="filled"
                color="primary"
                value={email}
                helperText={emailResponse}
                onChange={handleEmailChange}
                sx={{
                backgroundColor: 'white',
                borderRadius: 1,
                mr: 2,
                width: 'auto', // Adjust width as necessary
                }}
            />
            <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small" // Make button small to match TextField
                sx={{
                height: '40px', // Adjust the height to match the TextField if necessary
                }}
            >
                Submit
            </Button>
            </form>
        </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
