import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Link
} from '@mui/material';


function HelpDialog({ isDialogOpen, closeDialog }) {
    return (
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Objective</DialogTitle>
        <DialogContent>
          <Typography variant="body2" style={{ textAlign: 'left' }}>
            This tool is designed for land surveyors and mapping professionals to easily convert Public Land Survey System (PLSS) legal descriptions, Township Range Sections (TRS), and Quarter-Quarter Sections into visual formats, facilitating better understanding and communication of land locations.
          </Typography>
        </DialogContent>
        <DialogTitle>How to Enter Your Data</DialogTitle>
        <DialogContent>
          <Typography variant="body2" style={{ textAlign: 'left' }}>
            <strong>Township Range Section (TRS) Input: </strong>
            Enter the TRS in one of the following formats
            <ul>
              <li>1N 1W 10</li>
              <li>1N-1W-10</li>
              <li>1N1W10</li>
            </ul>
            <strong>Quarter-Quarter Section Input: </strong>
            To specify a more precise location within a section, use the Quarter-Quarter designation in formats like
            <ul>
              <li>NE/NE</li>
              <li>NE4NE4</li>
              <li>S/NE</li>
              <li>S2NE4</li>
              <li>N/NE/NE</li>
            </ul>
            <em>Note: If you need to convert multiple locations at once, our batch conversion option can handle this as well as lots. More states can be made avaliable upon request. Contact <Link href="mailto:plssgeoconvert@gmail.com">plssgeoconvert@gmail.com</Link> for any inquries.</em>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  export default HelpDialog;
