import React, { useState } from 'react';
import { TextField,Box, FormHelperText,Button, Dialog, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

function PlssForm({isOpen, onClose, addNewTract}) {
  const [state, setState] = useState('');
  const [townshipRangeSection, setTownshipRangeSection] = useState('');
  const [townshipError, setTownshipError] = useState('');
  const [validTRS, setValidTRS] = useState(false);
  const [fetchError, setFetchError] = useState('');
  const [validParcel, setValidParcel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [geoJSON, setGeoJSON] = useState(null);

  const fetchGeoJSON = async (formData) => {
    setIsLoading(true);
    const response = await fetch(`/plss/create_geojson`, {
      method: 'POST',
      body: formData,
    })
    setValidParcel(response.status === 200);
    if (response.status !== 200) {
      response.json().then((data) => {
        setFetchError(data.error);
        setGeoJSON(null)
      })
    } else {
      response.json().then((data) => {
        setGeoJSON(data.geojson);
      })
      setFetchError('');
    }
    setIsLoading(false)

  }

  const findParcel = async (trs) => {
    const formData = new FormData();
    formData.append('state', '');
    formData.append('county', '');
    formData.append('trs', trs);
    formData.append('qq', '');
    fetchGeoJSON(formData);
}

  // Handle Township Range and Section validation
  const handleTownshipChange = async (trs) => {
    const value = trs;
    setTownshipRangeSection(value);
    const formData = new FormData();
    formData.append('trs', value);
    const response = await fetch('/plss/verify_trs', {
        method: 'POST',
        body: formData,
    })
    setValidTRS(response.status === 200);

    if (response.status !== 200) {
      response.json().then((data) => {
        setTownshipError(data.error);
    })} else {
      setTownshipError('');
      findParcel(value);
    }
  };
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Data</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate autoComplete="off">
          <TextField
            label="State"
            placeholder="Colorado"
            fullWidth
            margin="normal"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />

          <TextField
            label="Township Range Section"
            placeholder="Format: 1N-1W-10 or 1N1W10"
            fullWidth
            margin="normal"
            value={townshipRangeSection}
            onChange={(e) => handleTownshipChange(e.target.value)}
            error={!validTRS && townshipRangeSection !== ''}
            helperText={townshipError}
          />

          {fetchError && !townshipError && townshipRangeSection !== "" && (
            <FormHelperText error={!validParcel} style={{ overflow: 'auto', maxHeight: '4rem' }}>
              {townshipError}
            </FormHelperText>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          onClick={() => {
            addNewTract(
              {
                geoJSON: geoJSON,
                state: state,
                TRS: townshipRangeSection,
                metadata: {}
              });
              onClose();
            }}
          disabled={!validParcel || isLoading || !validTRS} // Disable the button if not validParcel or isLoading
          variant="contained"
          loading={isLoading}
        >
          Add Tract
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default PlssForm;
