import React, { useEffect, useState, useRef } from 'react';
// import { DataGrid } from '@mui/x-data-grid';
import TractDataGrid from './TractDataGrid'
import AddTractDialog from './AddTractDialog';
import { Button, Grid, Paper, Box } from '@mui/material';
import TractMap from './TractMap';
import shpwrite from 'shp-write';

const tempRows = [
  { id: 1, TRS: '6N66W1', state: 'Colorado', metadata: {}, geoJSON:
    {
      "coordinates": [
          [
              [
                  -104.735900803,
                  40.520921024000074
              ],
              [
                  -104.73604208199998,
                  40.52444697000004
              ],
              [
                  -104.73106531699995,
                  40.52447806200007
              ],
              [
                  -104.72608857999995,
                  40.524509128000034
              ],
              [
                  -104.72111184299996,
                  40.524540193000064
              ],
              [
                  -104.71613507799998,
                  40.52457125900003
              ],
              [
                  -104.71609180299998,
                  40.52095267300007
              ],
              [
                  -104.71604849999994,
                  40.51733411500004
              ],
              [
                  -104.71596202699999,
                  40.513718640000036
              ],
              [
                  -104.71587555699995,
                  40.51010319400007
              ],
              [
                  -104.72068145199995,
                  40.51011368500008
              ],
              [
                  -104.72548731899997,
                  40.51012417600003
              ],
              [
                  -104.73029441099999,
                  40.510134667000045
              ],
              [
                  -104.73510150099997,
                  40.51014515700007
              ],
              [
                  -104.73542731499998,
                  40.51373429700004
              ],
              [
                  -104.73575313299995,
                  40.51732343800006
              ],
              [
                  -104.735900803,
                  40.520921024000074
              ]
          ]
      ],
      "type": "Polygon"
  }
  },
  { id: 2, TRS: '6N66W11', state: 'Colorado', metadata: {}, geoJSON:
    {
    "coordinates": [
        [
            [
                -104.75424295899995,
                40.50643807600005
            ],
            [
                -104.75431012399997,
                40.51005149400004
            ],
            [
                -104.74950799199996,
                40.51007488000005
            ],
            [
                -104.74470584599999,
                40.51009830700008
            ],
            [
                -104.73990367299996,
                40.51012173200007
            ],
            [
                -104.73510150099997,
                40.51014515700007
            ],
            [
                -104.73508266999994,
                40.50648990600007
            ],
            [
                -104.73506383999995,
                40.502834653000036
            ],
            [
                -104.73504499099994,
                40.49917940800003
            ],
            [
                -104.73502620799997,
                40.495524182000054
            ],
            [
                -104.73978004499997,
                40.49554256300007
            ],
            [
                -104.74453388199998,
                40.49556097200008
            ],
            [
                -104.74928771799995,
                40.49557935200005
            ],
            [
                -104.75404149299999,
                40.49559779600003
            ],
            [
                -104.75410864099996,
                40.49921121600005
            ],
            [
                -104.75417579499998,
                40.50282462800004
            ],
            [
                -104.75424295899995,
                40.50643807600005
            ]
        ]
    ],
    "type": "Polygon"
}},
  { id: 3, TRS: '6N66W12', state: 'Colorado', metadata: {}, geoJSON:
  {
    "coordinates": [
        [
            [
                -104.73508266999994,
                40.50648990600007
            ],
            [
                -104.73510150099997,
                40.51014515700007
            ],
            [
                -104.73029441099999,
                40.510134667000045
            ],
            [
                -104.72548731899997,
                40.51012417600003
            ],
            [
                -104.72068145199995,
                40.51011368500008
            ],
            [
                -104.71587555699995,
                40.51010319400007
            ],
            [
                -104.71588067099998,
                40.506462691000024
            ],
            [
                -104.715885787,
                40.50282221700007
            ],
            [
                -104.71589091199996,
                40.499181720000024
            ],
            [
                -104.71589607499999,
                40.49554124400004
            ],
            [
                -104.72067921899998,
                40.49553698500006
            ],
            [
                -104.72546233599996,
                40.49553269900008
            ],
            [
                -104.73024428499997,
                40.495528440000044
            ],
            [
                -104.73502620799997,
                40.495524182000054
            ],
            [
                -104.73504499099994,
                40.49917940800003
            ],
            [
                -104.73506383999995,
                40.502834653000036
            ],
            [
                -104.73508266999994,
                40.50648990600007
            ]
        ]
    ],
    "type": "Polygon"
}},
  { id: 4, TRS: '6N66W13', state: 'Colorado', metadata: {}, geoJSON:
  {
    "coordinates": [
        [
            [
                -104.73500817299998,
                40.49190943000008
            ],
            [
                -104.73502620799997,
                40.495524182000054
            ],
            [
                -104.73024428499997,
                40.495528440000044
            ],
            [
                -104.72546233599996,
                40.49553269900008
            ],
            [
                -104.72067921899998,
                40.49553698500006
            ],
            [
                -104.71589607499999,
                40.49554124400004
            ],
            [
                -104.71589284599997,
                40.491909571000065
            ],
            [
                -104.71588961999998,
                40.48827790100006
            ],
            [
                -104.71588639199996,
                40.48464620200008
            ],
            [
                -104.71588313799998,
                40.48101452900005
            ],
            [
                -104.72065025299997,
                40.48102719000008
            ],
            [
                -104.72541739399998,
                40.48103985100005
            ],
            [
                -104.73018570399995,
                40.48105251100003
            ],
            [
                -104.73495401399998,
                40.48106517200006
            ],
            [
                -104.73497207599996,
                40.484679923000044
            ],
            [
                -104.73499011099995,
                40.488294677000056
            ],
            [
                -104.73500817299998,
                40.49190943000008
            ]
        ]
    ],
    "type": "Polygon"
}
},
]

function ShapeManagement() {


  const [featureCollection, setFeatureCollection] = useState(null);
  const [rows, setRows] = useState([]);

  const downloadShapefile = async () => {
    try {
      const formData = new FormData();
      formData.append('geojson', JSON.stringify(featureCollection)); // Add the featureCollection as a JSON string to the FormData object
      const response = await fetch('/plss/multi_download_shapefile', {
        method: 'POST',
        body: formData, // Send the featureCollection as JSON
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob(); // Create a new Blob object with the shapefile data
      const downloadUrl = window.URL.createObjectURL(blob); // Create a URL for the Blob object
      const a = document.createElement('a'); // Create a new anchor element
      a.href = downloadUrl;
      a.download = 'shapefile.zip'; // Set the file name for download
      document.body.appendChild(a);
      a.click();
      a.remove(); // Clean up the DOM by removing the anchor element

      window.URL.revokeObjectURL(downloadUrl); // Release the created URL

      // Optional: Trigger a custom Google Analytics event
      if (window.gtag) {
        window.gtag('event', 'DownloadMultiShapefile', {
          'event_category': 'key_event',
          'event_label': 'Download Shapefile',
        });
      }
    } catch (error) {
      console.error('Failed to download Shapefile.', error);
    }
  };

  useEffect(() => {
    if (rows && rows.length > 0) {
      const tempFeature = {
        type: 'FeatureCollection',
        features: rows.map(row => ({
          type: 'Feature',
          properties: {
            id: row.id,
            TRS: row.TRS,
            state: row.state,
            metadata: row.metadata,
          },
          geometry: row.geoJSON,
        })),
      }
      setFeatureCollection(tempFeature);
    }}, [rows]);


  return (
    <Paper sx={{
      margin: "auto",
      width: "100%",
      display: "flex",
    }}>
    <Grid container spacing={0} style={{
        margin: 0,
        width: "100%",
        height: "100%",
        minHeight: "80vh"
        }}>
    <Grid item xs={12} md={4} sx={{
        display: 'flex',
        justifyContent: 'center', // Center children horizontally
        alignItems: 'center', // Center children horizontally in the flex container
        padding: "2.5%",
        position: "relative",
        minHeight: '400px'
      }}>
      <TractDataGrid rows={rows} setRows={setRows} downloadShapefile={downloadShapefile}/>
      </Grid>
      <TractMap geoJSON={featureCollection} isLoading={false}/>
      </Grid>
    </Paper>
  );
}

export default ShapeManagement;
