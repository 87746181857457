import React, {useState} from 'react';
import { AppBar, Toolbar, Typography, Box, Button } from '@mui/material';

import HelpDialog from './HelpDialog';

const Header = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openHelpDialog = () => {
    if (window.gtag) {
      window.gtag('event', 'help', {
        'event_category': 'key_event',
        'event_label': 'Open Help Dialog',
      });
    }
    setIsDialogOpen(true);
  };
  const closeHelpDialog = () => {
    setIsDialogOpen(false);
  };
  return (
    <AppBar position="static">
      <HelpDialog isDialogOpen={isDialogOpen} closeDialog={closeHelpDialog} />
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <img src="../logo192.png" alt="Logo" style={{ marginRight: 10, width: 'auto', height: '5vh'  }} />

          <Typography variant="h6" component="div">
            PLSS Geo Convert
          </Typography>
        </Box>
        <Button variant="outlined" color="inherit" onClick={openHelpDialog}>
          Help
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
