// Data for states and counties
const statesWithCounties = {
    'MS': [
      "Adams",
      "Alcorn",
      "Amite",
      "Attala",
      "Benton",
      "Bolivar",
      "Calhoun",
      "Carroll",
      "Chickasaw",
      "Choctaw",
      "Claiborne",
      "Clarke",
      "Clay",
      "Coahoma",
      "Copiah",
      "Covington",
      "DeSoto",
      "Forrest",
      "Franklin",
      "George",
      "Greene",
      "Grenada",
      "Hancock",
      "Harrison",
      "Hinds",
      "Holmes",
      "Humphreys",
      "Issaquena",
      "Itawamba",
      "Jackson",
      "Jasper",
      "Jefferson",
      "Jefferson Davis",
      "Jones",
      "Kemper",
      "Lafayette",
      "Lamar",
      "Lauderdale",
      "Lawrence",
      "Leake",
      "Lee",
      "Leflore",
      "Lincoln",
      "Lowndes",
      "Madison",
      "Marion",
      "Marshall",
      "Monroe",
      "Montgomery",
      "Neshoba",
      "Newton",
      "Noxubee",
      "Oktibbeha",
      "Panola",
      "Pearl River",
      "Perry",
      "Pike",
      "Pontotoc",
      "Prentiss",
      "Quitman",
      "Rankin",
      "Scott",
      "Sharkey",
      "Simpson",
      "Smith",
      "Stone",
      "Sunflower",
      "Tallahatchie",
      "Tate",
      "Tippah",
      "Tishomingo",
      "Tunica",
      "Union",
      "Walthall",
      "Warren",
      "Washington",
      "Wayne",
      "Webster",
      "Wilkinson",
      "Winston",
      "Yalobusha",
      "Yazoo"
    ],
    'MI': [
      "Alcona",
      "Alger",
      "Allegan",
      "Alpena",
      "Antrim",
      "Arenac",
      "Baraga",
      "Barry",
      "Bay",
      "Benzie",
      "Berrien",
      "Branch",
      "Calhoun",
      "Cass",
      "Charlevoix",
      "Cheboygan",
      "Chippewa",
      "Clare",
      "Clinton",
      "Crawford",
      "Delta",
      "Dickinson",
      "Eaton",
      "Emmet",
      "Genesee",
      "Gladwin",
      "Gogebic",
      "Grand Traverse",
      "Gratiot",
      "Hillsdale",
      "Houghton",
      "Huron",
      "Ingham",
      "Ionia",
      "Iosco",
      "Iron",
      "Isabella",
      "Jackson",
      "Kalamazoo",
      "Kalkaska",
      "Kent",
      "Keweenaw",
      "Lake",
      "Lapeer",
      "Leelanau",
      "Lenawee",
      "Livingston",
      "Luce",
      "Mackinac",
      "Macomb",
      "Manistee",
      "Marquette",
      "Mason",
      "Mecosta",
      "Menominee",
      "Midland",
      "Missaukee",
      "Monroe",
      "Montcalm",
      "Montmorency",
      "Muskegon",
      "Newaygo",
      "Oakland",
      "Oceana",
      "Ogemaw",
      "Ontonagon",
      "Osceola",
      "Oscoda",
      "Otsego",
      "Ottawa",
      "Presque Isle",
      "Roscommon",
      "Saginaw",
      "Sanilac",
      "Schoolcraft",
      "Shiawassee",
      "St. Clair",
      "St. Joseph",
      "Tuscola",
      "Van Buren",
      "Washtenaw",
      "Wayne",
      "Wexford"
    ],
    'LA': [
      "Acadia",
      "Allen",
      "Ascension",
      "Assumption",
      "Avoyelles",
      "Beauregard",
      "Bienville",
      "Bossier",
      "Caddo",
      "Calcasieu",
      "Caldwell",
      "Cameron",
      "Catahoula",
      "Claiborne",
      "Concordia",
      "De Soto",
      "East Baton Rouge",
      "East Carroll",
      "East Feliciana",
      "Evangeline",
      "Franklin",
      "Grant",
      "Iberia",
      "Iberville",
      "Jackson",
      "Jefferson",
      "Jefferson Davis",
      "Lafayette",
      "Lafourche",
      "LaSalle",
      "Lincoln",
      "Livingston",
      "Madison",
      "Morehouse",
      "Natchitoches",
      "Orleans",
      "Ouachita",
      "Plaquemines",
      "Pointe Coupee",
      "Rapides",
      "Red River",
      "Richland",
      "Sabine",
      "St. Bernard",
      "St. Charles",
      "St. Helena",
      "St. James",
      "St. John the Baptist",
      "St. Landry",
      "St. Martin",
      "St. Mary",
      "St. Tammany",
      "Tangipahoa",
      "Tensas",
      "Terrebonne",
      "Union",
      "Vermilion",
      "Vernon",
      "Washington",
      "Webster",
      "West Baton Rouge",
      "West Carroll",
      "West Feliciana",
      "Winn"
    ],
    'IL': [
      "Adams",
  "Alexander",
  "Bond",
  "Boone",
  "Brown",
  "Bureau",
  "Calhoun",
  "Carroll",
  "Cass",
  "Champaign",
  "Christian",
  "Clark",
  "Clay",
  "Clinton",
  "Coles",
  "Cook",
  "Crawford",
  "Cumberland",
  "DeKalb",
  "DeWitt",
  "Douglas",
  "DuPage",
  "Edgar",
  "Edwards",
  "Effingham",
  "Fayette",
  "Ford",
  "Franklin",
  "Fulton",
  "Gallatin",
  "Greene",
  "Grundy",
  "Hamilton",
  "Hancock",
  "Hardin",
  "Henderson",
  "Henry",
  "Iroquois",
  "Jackson",
  "Jasper",
  "Jefferson",
  "Jersey",
  "Jo Daviess",
  "Johnson",
  "Kane",
  "Kankakee",
  "Kendall",
  "Knox",
  "LaSalle",
  "Lake",
  "Lawrence",
  "Lee",
  "Livingston",
  "Logan",
  "Macon",
  "Macoupin",
  "Madison",
  "Marion",
  "Marshall",
  "Mason",
  "Massac",
  "McDonough",
  "McHenry",
  "McLean",
  "Menard",
  "Mercer",
  "Monroe",
  "Montgomery",
  "Morgan",
  "Moultrie",
  "Ogle",
  "Peoria",
  "Perry",
  "Piatt",
  "Pike",
  "Pope",
  "Pulaski",
  "Putnam",
  "Randolph",
  "Richland",
  "Rock Island",
  "Saline",
  "Sangamon",
  "Schuyler",
  "Scott",
  "Shelby",
  "St. Clair",
  "Stark",
  "Stephenson",
  "Tazewell",
  "Union",
  "Vermilion",
  "Wabash",
  "Warren",
  "Washington",
  "Wayne",
  "White",
  "Whiteside",
  "Will",
  "Williamson",
  "Winnebago",
  "Woodford"
  ],
  'OK': [
    "Adair",
    "Alfalfa",
    "Atoka",
    "Beaver",
    "Beckham",
    "Blaine",
    "Bryan",
    "Caddo",
    "Canadian",
    "Carter",
    "Cherokee",
    "Choctaw",
    "Cimarron",
    "Cleveland",
    "Coal",
    "Comanche",
    "Cotton",
    "Craig",
    "Creek",
    "Custer",
    "Delaware",
    "Dewey",
    "Ellis",
    "Garfield",
    "Garvin",
    "Grady",
    "Grant",
    "Greer",
    "Harmon",
    "Harper",
    "Haskell",
    "Hughes",
    "Jackson",
    "Jefferson",
    "Johnston",
    "Kay",
    "Kingfisher",
    "Kiowa",
    "Latimer",
    "Le Flore",
    "Lincoln",
    "Logan",
    "Love",
    "Major",
    "Marshall",
    "Mayes",
    "McClain",
    "McCurtain",
    "McIntosh",
    "Murray",
    "Muskogee",
    "Noble",
    "Nowata",
    "Okfuskee",
    "Oklahoma",
    "Okmulgee",
    "Osage",
    "Ottawa",
    "Pawnee",
    "Payne",
    "Pittsburg",
    "Pontotoc",
    "Pottawatomie",
    "Pushmataha",
    "Roger Mills",
    "Rogers",
    "Seminole",
    "Sequoyah",
    "Stephens",
    "Texas",
    "Tillman",
    "Tulsa",
    "Wagoner",
    "Washington",
    "Washita",
    "Woods",
    "Woodward"
    ],
    'FL': [
      "Alachua",
      "Baker",
      "Bay",
      "Bradford",
      "Brevard",
      "Broward",
      "Calhoun",
      "Charlotte",
      "Citrus",
      "Clay",
      "Collier",
      "Columbia",
      "DeSoto",
      "Dixie",
      "Duval",
      "Escambia",
      "Flagler",
      "Franklin",
      "Gadsden",
      "Gilchrist",
      "Glades",
      "Gulf",
      "Hamilton",
      "Hardee",
      "Hendry",
      "Hernando",
      "Highlands",
      "Hillsborough",
      "Holmes",
      "Indian River",
      "Jackson",
      "Jefferson",
      "Lafayette",
      "Lake",
      "Lee",
      "Leon",
      "Levy",
      "Liberty",
      "Madison",
      "Manatee",
      "Marion",
      "Martin",
      "Miami-Dade",
      "Monroe",
      "Nassau",
      "Okaloosa",
      "Okeechobee",
      "Orange",
      "Osceola",
      "Palm Beach",
      "Pasco",
      "Pinellas",
      "Polk",
      "Putnam",
      "St. Johns",
      "St. Lucie",
      "Santa Rosa",
      "Sarasota",
      "Seminole",
      "Sumter",
      "Suwannee",
      "Taylor",
      "Union",
      "Volusia",
      "Wakulla",
      "Walton",
      "Washington"
    ],
    'IN': [
      "Adams",
      "Allen",
      "Bartholomew",
      "Benton",
      "Blackford",
      "Boone",
      "Brown",
      "Carroll",
      "Cass",
      "Clark",
      "Clay",
      "Clinton",
      "Crawford",
      "Daviess",
      "Dearborn",
      "Decatur",
      "DeKalb",
      "Delaware",
      "Dubois",
      "Elkhart",
      "Fayette",
      "Floyd",
      "Fountain",
      "Franklin",
      "Fulton",
      "Gibson",
      "Grant",
      "Greene",
      "Hamilton",
      "Hancock",
      "Harrison",
      "Hendricks",
      "Henry",
      "Howard",
      "Huntington",
      "Jackson",
      "Jasper",
      "Jay",
      "Jefferson",
      "Jennings",
      "Johnson",
      "Knox",
      "Kosciusko",
      "LaGrange",
      "Lake",
      "LaPorte",
      "Lawrence",
      "Madison",
      "Marion",
      "Marshall",
      "Martin",
      "Miami",
      "Monroe",
      "Montgomery",
      "Morgan",
      "Newton",
      "Noble",
      "Ohio",
      "Orange",
      "Owen",
      "Parke",
      "Perry",
      "Pike",
      "Porter",
      "Posey",
      "Pulaski",
      "Putnam",
      "Randolph",
      "Ripley",
      "Rush",
      "Scott",
      "Shelby",
      "Spencer",
      "St. Joseph",
      "Starke",
      "Steuben",
      "Sullivan",
      "Switzerland",
      "Tippecanoe",
      "Tipton",
      "Union",
      "Vanderburgh",
      "Vermillion",
      "Vigo",
      "Wabash",
      "Warren",
      "Warrick",
      "Washington",
      "Wayne",
      "Wells",
      "White",
      "Whitley"
    ],
    'IA': [
      "Adair",
      "Adams",
      "Allamakee",
      "Appanoose",
      "Audubon",
      "Benton",
      "Black Hawk",
      "Boone",
      "Bremer",
      "Buchanan",
      "Buena Vista",
      "Butler",
      "Calhoun",
      "Carroll",
      "Cass",
      "Cedar",
      "Cerro Gordo",
      "Cherokee",
      "Chickasaw",
      "Clarke",
      "Clay",
      "Clayton",
      "Clinton",
      "Crawford",
      "Dallas",
      "Davis",
      "Decatur",
      "Delaware",
      "Des Moines",
      "Dickinson",
      "Dubuque",
      "Emmet",
      "Fayette",
      "Floyd",
      "Franklin",
      "Fremont",
      "Greene",
      "Grundy",
      "Guthrie",
      "Hamilton",
      "Hancock",
      "Hardin",
      "Harrison",
      "Henry",
      "Howard",
      "Humboldt",
      "Ida",
      "Iowa",
      "Jackson",
      "Jasper",
      "Jefferson",
      "Johnson",
      "Jones",
      "Keokuk",
      "Kossuth",
      "Lee",
      "Linn",
      "Louisa",
      "Lucas",
      "Lyon",
      "Madison",
      "Mahaska",
      "Marion",
      "Marshall",
      "Mills",
      "Mitchell",
      "Monona",
      "Monroe",
      "Montgomery",
      "Muscatine",
      "O'Brien",
      "Osceola",
      "Page",
      "Palo Alto",
      "Plymouth",
      "Pocahontas",
      "Polk",
      "Pottawattamie",
      "Poweshiek",
      "Ringgold",
      "Sac",
      "Scott",
      "Shelby",
      "Sioux",
      "Story",
      "Tama",
      "Taylor",
      "Union",
      "Van Buren",
      "Wapello",
      "Warren",
      "Washington",
      "Wayne",
      "Webster",
      "Winnebago",
      "Winneshiek",
      "Woodbury",
      "Worth",
      "Wright"
    ],
    'AL': [
      "Autauga",
      "Baldwin",
      "Barbour",
      "Bibb",
      "Blount",
      "Bullock",
      "Butler",
      "Calhoun",
      "Chambers",
      "Cherokee",
      "Chilton",
      "Choctaw",
      "Clarke",
      "Clay",
      "Cleburne",
      "Coffee",
      "Colbert",
      "Conecuh",
      "Coosa",
      "Covington",
      "Crenshaw",
      "Cullman",
      "Dale",
      "Dallas",
      "DeKalb",
      "Elmore",
      "Escambia",
      "Etowah",
      "Fayette",
      "Franklin",
      "Geneva",
      "Greene",
      "Hale",
      "Henry",
      "Houston",
      "Jackson",
      "Jefferson",
      "Lamar",
      "Lauderdale",
      "Lawrence",
      "Lee",
      "Limestone",
      "Lowndes",
      "Macon",
      "Madison",
      "Marengo",
      "Marion",
      "Marshall",
      "Mobile",
      "Monroe",
      "Montgomery",
      "Morgan",
      "Perry",
      "Pickens",
      "Pike",
      "Randolph",
      "Russell",
      "Shelby",
      "St. Clair",
      "Sumter",
      "Talladega",
      "Tallapoosa",
      "Tuscaloosa",
      "Walker",
      "Washington",
      "Wilcox",
      "Winston"
    ],
    'OH': [
      "Adams",
      "Allen",
      "Ashland",
      "Ashtabula",
      "Athens",
      "Auglaize",
      "Belmont",
      "Brown",
      "Butler",
      "Carroll",
      "Champaign",
      "Clark",
      "Clermont",
      "Clinton",
      "Columbiana",
      "Coshocton",
      "Crawford",
      "Cuyahoga",
      "Darke",
      "Defiance",
      "Delaware",
      "Erie",
      "Fairfield",
      "Fayette",
      "Franklin",
      "Fulton",
      "Gallia",
      "Geauga",
      "Greene",
      "Guernsey",
      "Hamilton",
      "Hancock",
      "Hardin",
      "Harrison",
      "Henry",
      "Highland",
      "Hocking",
      "Holmes",
      "Huron",
      "Jackson",
      "Jefferson",
      "Knox",
      "Lake",
      "Lawrence",
      "Licking",
      "Logan",
      "Lorain",
      "Lucas",
      "Madison",
      "Mahoning",
      "Marion",
      "Medina",
      "Meigs",
      "Mercer",
      "Miami",
      "Monroe",
      "Montgomery",
      "Morgan",
      "Morrow",
      "Muskingum",
      "Noble",
      "Ottawa",
      "Paulding",
      "Perry",
      "Pickaway",
      "Pike",
      "Portage",
      "Preble",
      "Putnam",
      "Richland",
      "Ross",
      "Sandusky",
      "Scioto",
      "Seneca",
      "Shelby",
      "Stark",
      "Summit",
      "Trumbull",
      "Tuscarawas",
      "Union",
      "Van Wert",
      "Vinton",
      "Warren",
      "Washington",
      "Wayne",
      "Williams",
      "Wood",
      "Wyandot"
    ],
    'MN': [
      "Aitkin",
      "Anoka",
      "Becker",
      "Beltrami",
      "Benton",
      "Big Stone",
      "Blue Earth",
      "Brown",
      "Carlton",
      "Carver",
      "Cass",
      "Chippewa",
      "Chisago",
      "Clay",
      "Clearwater",
      "Cook",
      "Cottonwood",
      "Crow Wing",
      "Dakota",
      "Dodge",
      "Douglas",
      "Faribault",
      "Fillmore",
      "Freeborn",
      "Goodhue",
      "Grant",
      "Hennepin",
      "Houston",
      "Hubbard",
      "Isanti",
      "Itasca",
      "Jackson",
      "Kanabec",
      "Kandiyohi",
      "Kittson",
      "Koochiching",
      "Lac qui Parle",
      "Lake",
      "Lake of the Woods",
      "Le Sueur",
      "Lincoln",
      "Lyon",
      "McLeod",
      "Mahnomen",
      "Marshall",
      "Martin",
      "Meeker",
      "Mille Lacs",
      "Morrison",
      "Mower",
      "Murray",
      "Nicollet",
      "Nobles",
      "Norman",
      "Olmsted",
      "Otter Tail",
      "Pennington",
      "Pine",
      "Pipestone",
      "Polk",
      "Pope",
      "Ramsey",
      "Red Lake",
      "Redwood",
      "Renville",
      "Rice",
      "Rock",
      "Roseau",
      "St. Louis",
      "Scott",
      "Sherburne",
      "Sibley",
      "Stearns",
      "Steele",
      "Stevens",
      "Swift",
      "Todd",
      "Traverse",
      "Wabasha",
      "Wadena",
      "Waseca",
      "Washington",
      "Watonwan",
      "Wilkin",
      "Winona",
      "Wright",
      "Yellow Medicine"
    ],
    'AR': [
      "Arkansas",
      "Ashley",
      "Baxter",
      "Benton",
      "Boone",
      "Bradley",
      "Calhoun",
      "Carroll",
      "Chicot",
      "Clark",
      "Clay",
      "Cleburne",
      "Cleveland",
      "Columbia",
      "Conway",
      "Craighead",
      "Crawford",
      "Crittenden",
      "Cross",
      "Dallas",
      "Desha",
      "Drew",
      "Faulkner",
      "Franklin",
      "Fulton",
      "Garland",
      "Grant",
      "Greene",
      "Hempstead",
      "Hot Spring",
      "Howard",
      "Independence",
      "Izard",
      "Jackson",
      "Jefferson",
      "Johnson",
      "Lafayette",
      "Lawrence",
      "Lee",
      "Lincoln",
      "Little River",
      "Logan",
      "Lonoke",
      "Madison",
      "Marion",
      "Miller",
      "Mississippi",
      "Monroe",
      "Montgomery",
      "Nevada",
      "Newton",
      "Ouachita",
      "Perry",
      "Phillips",
      "Pike",
      "Poinsett",
      "Polk",
      "Pope",
      "Prairie",
      "Pulaski",
      "Randolph",
      "Saline",
      "Scott",
      "Searcy",
      "Sebastian",
      "Sevier",
      "Sharp",
      "St. Francis",
      "Stone",
      "Union",
      "Van Buren",
      "Washington",
      "White",
      "Woodruff",
      "Yell"
    ],
    'WI': [
      "Adams",
      "Ashland",
      "Barron",
      "Bayfield",
      "Brown",
      "Buffalo",
      "Burnett",
      "Calumet",
      "Chippewa",
      "Clark",
      "Columbia",
      "Crawford",
      "Dane",
      "Dodge",
      "Door",
      "Douglas",
      "Dunn",
      "Eau Claire",
      "Florence",
      "Fond du Lac",
      "Forest",
      "Grant",
      "Green",
      "Green Lake",
      "Iowa",
      "Iron",
      "Jackson",
      "Jefferson",
      "Juneau",
      "Kenosha",
      "Kewaunee",
      "La Crosse",
      "Lafayette",
      "Langlade",
      "Lincoln",
      "Manitowoc",
      "Marathon",
      "Marinette",
      "Marquette",
      "Menominee",
      "Milwaukee",
      "Monroe",
      "Oconto",
      "Oneida",
      "Outagamie",
      "Ozaukee",
      "Pepin",
      "Pierce",
      "Polk",
      "Portage",
      "Price",
      "Racine",
      "Richland",
      "Rock",
      "Rusk",
      "Sauk",
      "Sawyer",
      "Shawano",
      "Sheboygan",
      "St. Croix",
      "Taylor",
      "Trempealeau",
      "Vernon",
      "Vilas",
      "Walworth",
      "Washburn",
      "Washington",
      "Waukesha",
      "Waupaca",
      "Waushara",
      "Winnebago",
      "Wood"
    ],
    'CO': [
      "Adams",
      "Alamosa",
      "Arapahoe",
      "Archuleta",
      "Baca",
      "Bent",
      "Boulder",
      "Broomfield",
      "Chaffee",
      "Cheyenne",
      "Clear Creek",
      "Conejos",
      "Costilla",
      "Crowley",
      "Custer",
      "Delta",
      "Denver",
      "Dolores",
      "Douglas",
      "Eagle",
      "Elbert",
      "El Paso",
      "Fremont",
      "Garfield",
      "Gilpin",
      "Grand",
      "Gunnison",
      "Hinsdale",
      "Huerfano",
      "Jackson",
      "Jefferson",
      "Kiowa",
      "Kit Carson",
      "La Plata",
      "Lake",
      "Larimer",
      "Las Animas",
      "Lincoln",
      "Logan",
      "Mesa",
      "Mineral",
      "Moffat",
      "Montezuma",
      "Montrose",
      "Morgan",
      "Otero",
      "Ouray",
      "Park",
      "Phillips",
      "Pitkin",
      "Prowers",
      "Pueblo",
      "Rio Blanco",
      "Rio Grande",
      "Routt",
      "Saguache",
      "San Juan",
      "San Miguel",
      "Sedgwick",
      "Summit",
      "Teller",
      "Washington",
      "Weld",
      "Yuma"
    ],
    'WY': [
      "Albany",
      "Big Horn",
      "Campbell",
      "Carbon",
      "Converse",
      "Crook",
      "Fremont",
      "Goshen",
      "Hot Springs",
      "Johnson",
      "Laramie",
      "Lincoln",
      "Natrona",
      "Niobrara",
      "Park",
      "Platte",
      "Sheridan",
      "Sublette",
      "Sweetwater",
      "Teton",
      "Uinta",
      "Washakie",
      "Weston"
    ],
    'UT': [
      "Beaver",
      "Box Elder",
      "Cache",
      "Carbon",
      "Daggett",
      "Davis",
      "Duchesne",
      "Emery",
      "Garfield",
      "Grand",
      "Iron",
      "Juab",
      "Kane",
      "Millard",
      "Morgan",
      "Piute",
      "Rich",
      "Salt Lake",
      "San Juan",
      "Sanpete",
      "Sevier",
      "Summit",
      "Tooele",
      "Uintah",
      "Wasatch",
      "Washington",
      "Wayne",
      "Weber"
  ],
    'ND': [
      "Adams",
      "Barnes",
      "Benson",
      "Billings",
      "Bottineau",
      "Bowman",
      "Burke",
      "Burleigh",
      "Cass",
      "Cavalier",
      "Dickey",
      "Divide",
      "Dunn",
      "Eddy",
      "Emmons",
      "Foster",
      "Golden Valley",
      "Grand Forks",
      "Grant",
      "Griggs",
      "Hettinger",
      "Kidder",
      "LaMoure",
      "Logan",
      "McHenry",
      "McIntosh",
      "McKenzie",
      "McLean",
      "Mercer",
      "Morton",
      "Mountrail",
      "Nelson",
      "Oliver",
      "Pembina",
      "Pierce",
      "Ramsey",
      "Ransom",
      "Renville",
      "Richland",
      "Rolette",
      "Sargent",
      "Sheridan",
      "Sioux",
      "Slope",
      "Stark",
      "Steele",
      "Stutsman",
      "Towner",
      "Traill",
      "Walsh",
      "Ward",
      "Wells",
      "Williams"
    ],
  };

export default statesWithCounties;
