// src/App.js

import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Route,
    Routes
} from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

// import FileUploader from './components/FileUploader';
import SingleShape from './components/SingleShape';
import ShapeManagement from './components/ShapeManagement';

function App() {
    return (
        <div className="App">
            <Header/>
                <Router>
                    <Routes>
                        <Route path="/" element={<SingleShape/>}/>
                        <Route path="/data-management" element={<ShapeManagement/>} />
                    </Routes>
                </Router>
            <Footer/>
         </div>
    );
}

export default App;
